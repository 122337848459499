import {getUserInfoByAdminUserIdOrUserNameApi, getUserInfoByUserIdOrUserNameApi} from "@/axios/apis"

export default {
    data() {
        return {
            userInfoList: [],
            adminUserInfoList: [],
        }
    },
    methods: {
        /** 用户搜索---注册用户 */
        async searchUserInfo(inner, type) {
            if (!inner) return
            const res = await getUserInfoByUserIdOrUserNameApi(inner)
            if (res.status !== "200") return
            const { records } = res.data;
            this.userInfoList = records
        },
        /** 用户搜索---管理后台用户 */
        async searchAdminUserInfo(inner) {
            if (!inner) return
            const res = await getUserInfoByAdminUserIdOrUserNameApi(inner)
            if (res.status !== "200") return
            const { records } = res.data;
            this.adminUserInfoList = records
        },
    }
}