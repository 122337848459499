import {objArrMapObj} from "@/untils/otherEvent"

/** 送评类型
 * A  放心购（平台订单，卖家包评级）
 * B 自主送评（平台订单，卖家不包评级，买家个人承担费用直接转评级）
 * C 个人延长收货送评（平台订单，收到货后，自费来送评）
 * D 个人送评（非平台订单的送评）
 * */
export const dqCheckPayTypeList = [
    {name: 'A类\n放心购', value: 'A', desc: '平台订单，卖家包评级'},
    {name: 'B类\n自主送评', value: 'B', desc: '平台订单，卖家不包评级，买家个人承担费用直接转评级'},
    {name: 'C类\n个人延长收货送评', value: 'C', desc: '平台订单，收到货后，自费来送评'},
    {name: 'D类\n个人送评', value: 'D', desc: '非平台订单的送评'},
]

export const dqCheckPayClassType = {
    A: 'color-green',
    B: 'color-blue',
    C: 'color-cadetblue',
    D: 'color-orange'
}

export const whiteUserManageType = [
    {value: 45, name: '参拍禁拍黑名单'},
    {value: 30, name: '发拍差品灰名单'},
    {value: 60, name: '发布黑名单'},
    {value: 70, name: '提现黑名单'},
    {value: 10, name: '拍卖出价白名单'},
    {value: 20, name: '开店白名单'},
    {value: 50, name: '委托白名单'},
    {value: 80, name: '出价免保证金白名单'},
    {value: 90, name: '刷单风险黑名单'},
]
/** 用户白名单添加那个类型需要进行消息通知: 60: 发布黑名单， */
export const userWhiteListMessagePushTypeList = [
    60
]
export const riskConfigManageType = [
    {value: 0, name: '黑名单用户'},
    {value: 5, name: '待观察名单用户'},
    {value: 8, name: '黑名单ip'},
]

export const blockTime = [
    {value: '7', name: '7天'},
    {value: '30', name: '一个月'},
    {value: '90', name: '3个月'},
    {value: '180', name: '半年'},
    {value: '360', name: '一年'},
    {value: '3600', name: '永久'},
]

export const tagDayList = [
    {id: 7, name: '7天', selected: false},
    {id: 15, name: '15天', selected: false},
    {id: 30, name: '30天', selected: false},
]

/** 评级状态 5待发货    10待评级    15评级中   20已评级待确认   25鉴定确认(评级完成)*/
export const rateServiceStatusTypeList = [
    {value: -1, name: '评级取消'},
    {value: 5, name: '待发货'},
    {value: 10, name: '待评级'},
    {value: 15, name: '评级中'},
    {value: 20, name: '已评级待确认'},
    {value: 25, name: '鉴定确认(评级完成)'},
]
/** 对应的样式类 */
export const rateServiceStatusClassType = {
    5: 'color-red',
    10: 'color-green',
    15: 'color-orange',
    20: 'color-blue',
    25: 'color-gray'
}

/** 支付状态 0未支付，2已支付，4已扣除，5可退回，6已退回*/
export const payStatusTypeList = [
    {value: 0, name: '未支付'},
    {value: 2, name: '已支付'},
    {value: 4, name: '已扣除'},
    {value: 5, name: '可退回'},
    {value: 6, name: '已退回'},
]
/** 支付状态 对应的样式类 */
export const payStatusClassType = {
    0: 'color-red',
    2: 'color-green',
    4: 'color-orange',
    5: 'color-blue',
    6: 'color-gray'
}

/** 支付类型 */
export const payTypeList = [
    { value: 1, name: '微信小程序JSAPI支付'},
    { value: 2, name: '微信APP支付'},
    { value: 3, name: '支付宝APP支付'},
    { value: 4, name: 'IOS内购'},
    { value: 5, name: '银行卡支付'},
    { value: 8, name: '线下支付'},
]
/** 支付类型---快递费 */
export const payTypeByLogisticsPriceList = [
    { value: 1, name: '微信小程序JSAPI支付'},
    { value: 3, name: '支付宝APP支付'},
    { value: 5, name: '银行卡支付'},
    { value: 8, name: '取消发货退回'},
]
/** 支付类型 类 */
export const payTypeListClassType = {
    1: 'color-green',
    2: 'color-green',
    3: 'color-blue',
    4: 'color-dq-blue',
    5: 'color-orange',
    8: 'color-red',
}

/** 拉黑时长列表 */
export const BLACK_LINK_TIME_LIST = [
    { name: '一天', value: 86400 },
    { name: '七天', value: 604800 },
    { name: '一个月', value: 2592000 },
    { name: '三个月', value: 7776000 },
    { name: '12个月', value: 31536000 },
    { name: '永久', value: 4294967295 },
]

export const payTypeListMapType = (value) => {
    return objArrMapObj('value', 'name', payTypeList)[value]
}

export const payTypeByLogisticsPriceListMapType = (value) => {
    return objArrMapObj('value', 'name', payTypeByLogisticsPriceList)[value]
}

export const computedRateServiceStatusMapType = (value) => {
    return objArrMapObj('value', 'name', rateServiceStatusTypeList)[value]
}

export const rateServicePayTypeMapText = (value) => {
    return objArrMapObj('value', 'name', dqCheckPayTypeList)[value]
}

export const payStatusTypeMapText = (value) => {
    return objArrMapObj('value', 'name', payStatusTypeList)[value]
}

export const rateServicePayTypeMapTextByDesc = (value) => {
    return objArrMapObj('value', 'desc', dqCheckPayTypeList)[value]
}


/** 用户vip等级 */
export const vip_0 = 'https://image.douquan.com/prod/admin/coin/2024-10-08/1728356175166751.png'
export const vip_1 = 'https://image.douquan.com/static/vipIcon/vip_1.png'
export const vip_2 = 'https://image.douquan.com/static/vipIcon/vip_2.png'
export const vip_3 = 'https://image.douquan.com/static/vipIcon/vip_3.png'
export const vip_4 = 'https://image.douquan.com/static/vipIcon/vip_4.png'
export const vip_5 = 'https://image.douquan.com/static/vipIcon/vip_5.png'
export const vip_6 = 'https://image.douquan.com/static/vipIcon/vip_6.png'
export const vip_7 = 'https://image.douquan.com/static/vipIcon/vip_7.png'
export const vip_8 = 'https://image.douquan.com/static/grade/vip_8.png'

export const user_vip_level_icon = {
    0: vip_0,
    1: vip_1,
    2: vip_2,
    3: vip_3,
    4: vip_4,
    5: vip_5,
    6: vip_6,
    7: vip_7,
    8: vip_8
}