var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemProductNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [_vm._v(" " + _vm._s(row.productName) + " ")]),
                _c(
                  "div",
                  {
                    staticClass: "color-blue cur-pot mt-10",
                    on: {
                      click: function($event) {
                        return _vm.handleGoToProductList(row, row.productNo)
                      }
                    }
                  },
                  [_vm._v(" 【" + _vm._s(row.productNo) + "】 ")]
                ),
                _c("div", { staticClass: "mt-10" }, [
                  _vm._v(" 【"),
                  _c(
                    "span",
                    { class: row.productKind ? "color-green" : "color-orange" },
                    [_vm._v(_vm._s(row.productKind ? "一口价" : "拍品"))]
                  ),
                  _vm._v("】 ")
                ]),
                row.productEndTime
                  ? _c("div", { staticClass: "color-gray mt-10" }, [
                      _vm._v("截拍时间：" + _vm._s(row.productEndTime))
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemAuctionImageSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.productImageList
                  ? _c(
                      "div",
                      { staticClass: "img-list-sp" },
                      _vm._l(row.productImageList, function(item, index) {
                        return _c("img", {
                          key: index,
                          attrs: {
                            src:
                              item +
                              "?x-oss-process=image/resize,p_80/format,webp/resize,w_200",
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.handlePreviewImg(
                                row.productImageList,
                                index
                              )
                            }
                          }
                        })
                      }),
                      0
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemReportUserInfoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", {}, [
                  _c("span", { staticClass: "color-black" }, [
                    _vm._v(_vm._s(row.userName))
                  ]),
                  _c("span", { staticClass: "color-green" }, [
                    _vm._v("【" + _vm._s(row.userId) + "】")
                  ])
                ]),
                _c("div", { staticClass: "mt-10" }, [
                  _c("span", { class: _vm.reportTypeClassType[row.shamType] }, [
                    _vm._v(
                      _vm._s(_vm.computedReportTypeListMapText(row.shamType))
                    )
                  ])
                ]),
                _c("div", { staticClass: "mt-10" }, [
                  _vm._v(_vm._s(row.reportContent))
                ]),
                _c("div", { staticClass: "color-gray mt-10" }, [
                  _vm._v("举报时间：" + _vm._s(row.reportTime))
                ])
              ])
            }
          },
          {
            key: "itemReportAuctionImageSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.reportImageList
                  ? _c(
                      "div",
                      { staticClass: "img-list" },
                      _vm._l(row.reportImageList, function(item, index) {
                        return _c("img", {
                          key: index,
                          attrs: { src: item, alt: "" },
                          on: {
                            click: function($event) {
                              return _vm.handlePreviewImg(
                                row.reportImageList,
                                index
                              )
                            }
                          }
                        })
                      }),
                      0
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemCheckAuctionTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    class:
                      _vm.reportAuctionCheckResultClassType[row.handleStatus]
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.computedReportAuctionCheckResultTypeListMapText(
                            row.handleStatus
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _c("div", { staticClass: "color-gray mt-10" }, [
                  _vm._v("操作人：" + _vm._s(row.adminName))
                ])
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", type: "link" },
                      on: {
                        click: function($event) {
                          return _vm.handleGoToProductList(row, row.productNo)
                        }
                      }
                    },
                    [_vm._v("查看该币详情")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-colum-start-center mt-10" },
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "是否确认为假币？",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.handleConfirmReportCheckResult(
                                row.id,
                                3
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "mt-10",
                              attrs: { size: "small", type: "danger" }
                            },
                            [_vm._v("确认假币")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "是否确定为真币？",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.handleConfirmReportCheckResult(
                                row.id,
                                6
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "mt-10",
                              attrs: { size: "small", type: "primary" }
                            },
                            [_vm._v("不是假币")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "是否审核为不确定？",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.handleConfirmReportCheckResult(
                                row.id,
                                9
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            { staticClass: "mt-10", attrs: { size: "small" } },
                            [_vm._v("不确定")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }