<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  拍品名称    -->
      <div slot="itemProductNameSlot" slot-scope="row">
        <div>
          {{ row.productName }}
        </div>
        <div class="color-blue cur-pot mt-10" @click="handleGoToProductList(row, row.productNo)">
          【{{ row.productNo }}】
        </div>
        <div class="mt-10">
          【<span :class="row.productKind ? 'color-green' : 'color-orange'">{{ row.productKind ? '一口价' : '拍品' }}</span>】
        </div>
        <div class="color-gray mt-10" v-if="row.productEndTime">截拍时间：{{ row.productEndTime }}</div>
      </div>
<!--  拍品图片    -->
      <div slot="itemAuctionImageSlot" slot-scope="row">
        <div class="img-list-sp" v-if="row.productImageList">
          <img
              v-for="(item, index) in row.productImageList"
              :key="index"
              :src="item + '?x-oss-process=image/resize,p_80/format,webp/resize,w_200'"
              alt=""
              @click="handlePreviewImg(row.productImageList, index)"
          >
        </div>
      </div>
<!--  举报信息    -->
      <div slot="itemReportUserInfoSlot" slot-scope="row">
        <div class="">
          <span class="color-black">{{ row.userName }}</span>
          <span class="color-green">【{{ row.userId }}】</span>
        </div>
        <div class="mt-10">
          <span :class="reportTypeClassType[row.shamType]">{{ computedReportTypeListMapText(row.shamType) }}</span>
        </div>
        <div class="mt-10">{{ row.reportContent }}</div>
        <div class="color-gray mt-10">举报时间：{{ row.reportTime }}</div>
      </div>
<!--  举报图片    -->
      <div slot="itemReportAuctionImageSlot" slot-scope="row">
        <div class="img-list" v-if="row.reportImageList">
          <img
              v-for="(item, index) in row.reportImageList"
              :key="index"
              :src="item"
              alt=""
              @click="handlePreviewImg(row.reportImageList, index)"
          >
        </div>
      </div>
<!--  审核装填    -->
      <div slot="itemCheckAuctionTypeSlot" slot-scope="row">
        <span :class="reportAuctionCheckResultClassType[row.handleStatus]">
          {{ computedReportAuctionCheckResultTypeListMapText(row.handleStatus) }}
        </span>
        <div class="color-gray mt-10">操作人：{{ row.adminName }}</div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button
            size="small"
            type="link"
            @click="handleGoToProductList(row, row.productNo)"
        >查看该币详情</a-button>
        <div class="flex-colum-start-center mt-10">
          <a-popconfirm
              title="是否确认为假币？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleConfirmReportCheckResult(row.id, 3)"
          >
            <a-button size="small" type="danger" class="mt-10">确认假币</a-button>
          </a-popconfirm>
          <a-popconfirm
              title="是否确定为真币？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleConfirmReportCheckResult(row.id, 6)"
          >
            <a-button size="small" type="primary" class="mt-10">不是假币</a-button>
          </a-popconfirm>
          <a-popconfirm
              title="是否审核为不确定？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleConfirmReportCheckResult(row.id, 9)"
          >
            <a-button size="small" class="mt-10">不确定</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
  </div>
</template>
<script>
import {
  REPORT_AUCTION_CHECK_RESULT_CLASS_TYPE,
  REPORT_TYPE_CLASS_TYPE, reportAuctionCheckResultTypeListMapText,
  reportAuctionTableColumns,
  reportTypeListMapText
} from "@/views/cmsPage/productManage/userReportAuctionList/_data"
import {postConfirmReportCheckResult} from "@/views/cmsPage/productManage/userReportAuctionList/_apis"

export default {
  props: ['pagination', 'list'],
  data() {
    return {
      tableList: reportAuctionTableColumns,
      reportTypeClassType: REPORT_TYPE_CLASS_TYPE,

      reportAuctionCheckResultClassType: REPORT_AUCTION_CHECK_RESULT_CLASS_TYPE,
    }
  },
  computed: {
    computedReportTypeListMapText() {
      return (value) => reportTypeListMapText(value)
    },
    computedReportAuctionCheckResultTypeListMapText() {
      return (value) => reportAuctionCheckResultTypeListMapText(value)
    }
  },
  methods: {
    /** 确定举报结果 */
    async handleConfirmReportCheckResult(id, type) {
      this.$loading.show()
      const res = await postConfirmReportCheckResult({ id, handleStatus: type })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('审核成功')
      this.$emit('success')
    },
    /** 监听到了另一个标签页面驳回 */
    handleWatchOtherPageTag(e) {
      if (e.data) {
        this.$emit('watchSuccess')
      }
    },
    /** 去往拍品列表页面 */
    handleGoToProductList(row, productNo) {
      // const channel = new BroadcastChannel('my-channel');
      // channel.onmessage = this.handleWatchOtherPageTag
      const origin = location.origin
      let url
      if (row.productKind) {
        url = `${origin}/#/cms/currencymannage/goods?productName=${row.productId}`
      } else {
        url = `${origin}/#/cms/currencymannage/auctiongoods?productName=${productNo}&sourceType=report`;
      }
      window.open(url)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.color-green {
  color: green;
}
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
    height: 50px;
  }
}
.img-list-sp {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
    height: 50px;
  }
}
</style>